import actions from './actions';
import { CustomViewsReportsService } from '../../services/customViewsReportsService';

const { customViewsReportsBegin, customViewsReportsSuccess, customViewsReportsErr } = actions;

const getCustomViewsReports = () => {
  return async (dispatch) => {
    try {
      dispatch(customViewsReportsBegin());
      const customViewsReports = await CustomViewsReportsService.getCustomViewsReports();
      dispatch(customViewsReportsSuccess(customViewsReports));
    } catch (error) {
      dispatch(customViewsReportsErr(error));
    }
  };
};

export { getCustomViewsReports};
