import { PageHeader } from 'antd';
import React from 'react';
// import { EditMainPage } from './components/EditMainPage';
import { useParams } from 'react-router-dom';
import { TextUsers, SpaceText } from './Style';
import { UsersTable } from './components/UsersTable';
import useCountUser from './hook/useCountUser';
import { Main } from '../../container/styled';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { BulletIcon } from '../../components/utilities/appIcons';
import { ProfileTypes } from '../../config/constants/userConstants';
import { useColorList } from '../../hooks/useColorList';

export const ListUsers = () => {
  const { incidentTypeId } = useParams();
  const { translate: t } = useDictionaryTranslation();
  const { countUsers } = useCountUser();
  const { getColorById } = useColorList();

  return (
    <>
      <PageHeader title={t('users')} ghost />
      <SpaceText size="large" block>
        {countUsers.data.map((profileData) => {
          const currentProfile = Object.values(ProfileTypes).find((item) => item.id === profileData.profileId);

          return (
            <TextUsers>
              <BulletIcon color={getColorById(profileData.idColor)} />
              <span className="count">{profileData.total}</span>
              {t(currentProfile.name)}
            </TextUsers>
          );
        })}
      </SpaceText>
      <Main>
        <UsersTable incidentTypeId={incidentTypeId} />
      </Main>
    </>
  );
};
