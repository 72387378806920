import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Admin from '.';
import ProtectedRoute from '../../components/utilities/protectedRoute';
import { useAppRoutes } from '../../hooks/useAppRoutes';
import PendingsBoard from '../../views/pendingsBoard/PendingsBoard'
import { getDictionary } from '../../redux/dictionary/actionCreator';
import { getCustomViewsReports } from '../../redux/customViewsReports/actionCreator';
import { getColorList } from '../../redux/colors/actionCreator';


const AuthenticatedApp = () => {
  const dispatch = useDispatch();
  const { defaultRoute } = useAppRoutes();

  useEffect(() => {
    dispatch(getCustomViewsReports());
    dispatch(getDictionary());
    dispatch(getColorList());
  }, [dispatch]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={defaultRoute} />} />
        <Route path="/pendings-board" component={PendingsBoard} />
        <ProtectedRoute path="/" component={Admin} />
        <Redirect to={defaultRoute} />
      </Switch>
    </Router>
  );
};

export default AuthenticatedApp;
