import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/admin/withAdminLayout';
import { useAppRoutes } from '../../hooks/useAppRoutes';


const NestedRoutes = ({ routes }) => {
  const currentRoute = useRouteMatch();
  const { path } = currentRoute;

  return (
    <Switch>
      {routes.map((route) => {
        return <Route exact key={route.name} path={`${path}${route.path}`} component={route.component} />;
      })}
      <Redirect to="/" />
    </Switch>
  );
};

const Admin = () => {

  const { userRoutes: appRoutes, userCustomRoutes } = useAppRoutes();

  

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        {appRoutes.map((route) => {
          if (route.children) {
            return (
              <Route
                key={route.path}
                path={route.path}
                render={(routeProps) => {
                  return <NestedRoutes {...routeProps} routes={route.children} />;
                }}
              />
            );
          }
          return <Route exact key={route.path} path={route.path} component={route.component} />;
        })}
        {userCustomRoutes.map((route) => {
          return <Route exact key={route.path} path={route.path} component={route.component} />;
        })}
        {appRoutes?.length > 0 ? <Redirect to="/" /> : null}
      </Switch>
    </Suspense>
  );
};

NestedRoutes.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      component: PropTypes.node,
    }),
  ),
};

export default withAdminLayout(Admin);
