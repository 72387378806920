import { Col, Form } from 'antd';
import { SelectCompany } from '../../../components/services/forms/SelectCompany';
import { TitleFormDefault } from '../../../container/styled';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { CustomSwitch } from '../../selfScheduling/components/form/editScheduleBlocks/customSwitch';

const FormInterested = () => {
  const { translate: t } = useDictionaryTranslation();
  return (
    <>
      <Col xs={24} md={24}>
        <Form.Item
          name="assignedCompanies"
          rules={[{ message: t('customerRequired'), required: true }]}
          label={t('customer')}
          valuePropName="selectedCompany"
        >
          <SelectCompany multiple />
        </Form.Item>
      </Col>

      <Col xs={24} md={20}>
        <TitleFormDefault>{t('creationOfRequests')}</TitleFormDefault>

        <h4>{t('creationOfRequestsDescription')}</h4>
        <Form.Item name="canCreatePetitions">
          <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
        </Form.Item>
      </Col>
    </>
  );
};

export default FormInterested;
