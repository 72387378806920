import { useSelector } from 'react-redux';
import { useMemo, lazy } from 'react';
import { Routes, defaultPath } from '../config/routes';
import { ProfileTypes } from '../config/constants/userConstants';

const CustomViewsReports = lazy(() => import('../views/customViewsReports/CustomViewsReports'));
const defaultRoutes = {
  [ProfileTypes.ADMIN.id]: '/dashboard',
  [ProfileTypes.OPERATOR.id]: '/calendar',
};

export const useAppRoutes = () => {
  const { currentUserProfile, permissions, accountModules, customViewsReports } = useSelector((state) => ({
    currentUserProfile: state.auth.currentUser?.profile?.idProfile,
    permissions: state.auth.permissions,
    accountModules: state.auth.accountModules,
    customViewsReports: state.customViewsReports.data.customViewsReports
  }));

  const userRoutes = useMemo(() => {
    const filterRoutes = (routes) => {
      const checkPermissions = (route) => {
        if (!route.profiles.includes(currentUserProfile) && !permissions.manageAccounts) {
          return false;
        }
        if (route.moduleName != null && !accountModules?.[route.moduleName]) {
          return false;
        }
        if (typeof route.checkPermissions !== 'function') {
          return true;
        }
        return route.checkPermissions(permissions);
      };

      return routes.reduce((newRoutes, route) => {
        if (checkPermissions(route)) {
          const children = route.children && filterRoutes(route.children);
          if (!route.children || children?.length) {
            newRoutes.push({ ...route, children });
          }
        }
        return newRoutes;
      }, []);
    };
    return filterRoutes(Routes);
  }, [currentUserProfile, permissions, accountModules]);

  const userCustomRoutes = useMemo(() => {
    const customRoutes = customViewsReports.map(item => (
      {
        path: item.path,
        name: item.title,
        icon: item.icon || 'file-text',
        profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id, ProfileTypes.MASTER.id],
        component: CustomViewsReports,
      }
    ))
    return customRoutes;
  }, [currentUserProfile, customViewsReports]);


  return {
    userRoutes,
    userCustomRoutes,
    defaultRoute: defaultRoutes[currentUserProfile] ?? userRoutes[0]?.path ?? defaultPath,
  };
};
