import { ProfileTypes } from '../../../config/constants/userConstants';

export function getPermissions(userProfile = {}, accountData = {}) {
  const isAdmin = userProfile.idProfile === ProfileTypes.ADMIN.id;
  const isMaster = userProfile.idProfile === ProfileTypes.MASTER.id;
  return {
    canEditServices: isMaster || isAdmin || userProfile.administrateServices,
    enableAccountCustomization: accountData.enableAccountCustomization,
    enableAccountAPI: accountData.enableAccountAPI,
    enableAccountFieldWork: accountData.enableAccountFieldWork,
    manageAccounts: isMaster && userProfile.idAccount != null,
    canCreateIncidentNotes: isMaster || isAdmin || userProfile.canCreateIncidentNotes,
    canChangeServiceStatus: isAdmin || userProfile.canChangeServiceStatus,
    canAdministrateCustomers: isMaster || isAdmin || userProfile.administrateClients,
    administrateAdvancedAvailability: isMaster || isAdmin || userProfile.administrateAdvancedAvailability,
  };
}
