import actions from './actions';

const { CUSTOM_VIEWS_REPORTS_BEGIN, CUSTOM_VIEWS_REPORTS_SUCCESS, CUSTOM_VIEWS_REPORTS_ERR } = actions;

const initState = {
  loading: false,
  data: {
    customViewsReports:[]
  },
};

const CustomViewsReportsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CUSTOM_VIEWS_REPORTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CUSTOM_VIEWS_REPORTS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CUSTOM_VIEWS_REPORTS_ERR:
      return {
        ...state,
        err,
        loading: false,
      };
    default:
      return state;
  }
};

export default CustomViewsReportsReducer;
