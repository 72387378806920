import { useMemo } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Form, Divider } from 'antd';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { CustomSwitch } from '../../selfScheduling/components/form/editScheduleBlocks/customSwitch';
import { SelectHeadquarters } from '../../../components/headquarters/SelectHeadquarters';
import { SelectServiceType } from '../../../components/services/forms/SelectServiceType';
import { SelectUser } from '../../../components/users/SelectUser';
import { ProfileTypes } from '../../../config/constants/userConstants';
import { SelectSkills } from '../../../components/skills/SelectSkills';
import { theme } from '../../../config/theme/themeVariables';
import { TitleFormDefault } from '../../../container/styled';

const FormOperator = ({ headquarters, viewAllServices, onChangeHeadquarters, onChangeViewAllServices }) => {
  const { translate: t } = useDictionaryTranslation();
  const headquartersIds = useMemo(
    () => (headquarters?.length > 0 ? headquarters.map((x) => x.value) : [null]),
    [headquarters],
  );

  const enableFieldWork = useSelector((state) => state.auth.accountData.enableFieldWork);

  return (
    <>
      <Col xs={12} md={12}>
        <Form.Item name="administrateClients" label={t('editInfoCustomers')} valuePropName="checked">
          <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
        </Form.Item>
      </Col>
      <Col xs={12} md={12}>
        <Form.Item name="canChangeServiceStatus" label={t('infoServiceStatus')} valuePropName="checked">
          <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
        </Form.Item>
      </Col>
      <Col xs={12} md={12}>
        <Form.Item name="administrateAdvancedAvailability" label={t('infoSwitchAvailability')} valuePropName="checked">
          <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
        </Form.Item>
      </Col>
      <Col xs={12} md={12}>
        <Form.Item name="canCreateIncidentNotes" label={t('addNotesDescription')} valuePropName="checked">
          <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
        </Form.Item>
      </Col>
      <Divider />
      <Col xs={24} md={20}>
        <TitleFormDefault>{t('skills')}</TitleFormDefault>
        <span>{t('skillsDescription')}</span>
        <Link to="/settings/skills/">
          <span style={{ color: theme['primary-color'] }}>{t('skillsDescriptionLink')}</span>
        </Link>
        <span>{t('skillsDescriptionFinish')}</span>
        <Form.Item name="skills">
          <SelectSkills multiple />
        </Form.Item>
      </Col>
      <Divider />
      <Col xs={24} md={20}>
        <TitleFormDefault>{t('headquarter')}</TitleFormDefault>
        <h4>{t('headquarterDescription')}</h4>
        <Form.Item name="headquarters">
          <SelectHeadquarters multiple onChange={onChangeHeadquarters} />
        </Form.Item>
      </Col>
      <Divider />
      <Col xs={24} md={20}>
        <TitleFormDefault>{t('resourceManagement')}</TitleFormDefault>
        <h4>{t('resourceDescription')}</h4>
        <Form.Item name="resources" valuePropName="selectedUser">
          <SelectUser
            multiple
            headquarters={headquartersIds}
            placeholder={t('resourcePlaceholder')}
            idProfile={ProfileTypes.TECHNICIAN.id}
          />
        </Form.Item>
      </Col>
      <Divider />
      <Col xs={10} md={20}>
        <h4>
          <TitleFormDefault>{t('serviceAssignment')}</TitleFormDefault>
        </h4>
        <Form.Item name="administrateServices" label={t('administrateServices')} valuePropName="checked">
          <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
        </Form.Item>
        <Form.Item name="seeAllServices" label={t('seeAllServices')} valuePropName="checked">
          <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} onChange={onChangeViewAllServices} />
        </Form.Item>
        {!viewAllServices ? (
          <Form.Item
            name="servicesTypesAssigned"
            label={t('servicesTypesAssigned')}
            valuePropName="selectedServiceType"
          >
            <SelectServiceType multiple />
          </Form.Item>
        ) : null}
      </Col>
      <Divider />
      {enableFieldWork ? (
        <>
          <Col xs={10} md={20}>
            <h4>
              <TitleFormDefault>{t('task')}</TitleFormDefault>
            </h4>
            <Form.Item name="administrateTask" label={t('administrateTask')} valuePropName="checked">
              <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
            </Form.Item>
            <Form.Item name="seeAllTask" label={t('seeAllTask')} valuePropName="checked">
              <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
            </Form.Item>
          </Col>
          <Divider />
          <Col xs={10} md={20}>
            <h4>
              <TitleFormDefault>{t('workflow')}</TitleFormDefault>
            </h4>
            <Form.Item name="administrateWorkflows" label={t('administrateWorkflows')} valuePropName="checked">
              <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
            </Form.Item>
            <Form.Item name="seeAllWorkflows" label={t('seeAllWorkflows')} valuePropName="checked">
              <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
            </Form.Item>
          </Col>
        </>
      ) : null}
    </>
  );
};

FormOperator.propTypes = {
  headquarters: PropTypes.array,
  viewAllServices: PropTypes.bool,
  onChangeHeadquarters: PropTypes.func,
  onChangeViewAllServices: PropTypes.func,
};

export default FormOperator;
