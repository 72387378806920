import { combineReducers } from 'redux';
import ChangeLayoutMode from './themeLayout/reducers';

import authReducer from './authentication/reducers';
import DictionaryReducer from './dictionary/reducers';
import SelfSchedulingReducer from './selfScheduling/reducers';
import ColorsReducer from './colors/reducers';
import CustomHtmlReducer from './customHtml/reducers';
import { chatReducer, SingleChatReducer, groupChatReducer, SingleChatGroupReducer } from './chat/reducers';
import CustomViewsReportsReducer from './customViewsReports/reducers';

const rootReducers = combineReducers({
  // APP REDUCERS
  ChangeLayoutMode,
  auth: authReducer,
  dictionary: DictionaryReducer,
  selfScheduling: SelfSchedulingReducer,
  colorList: ColorsReducer,
  customHtml: CustomHtmlReducer,
  chatSingle: SingleChatReducer,
  chatSingleGroup: SingleChatGroupReducer,
  chat: chatReducer,
  groupChat: groupChatReducer,
  customViewsReports: CustomViewsReportsReducer,
});

export default rootReducers;
