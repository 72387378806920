import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class CustomViewsReportsService {
  static async getCustomViewsReports() {
    const { data } = await DataService.get(API.customViewsReports.root);
    return CustomViewsReportsService.transformCustomViewsReport(data)
  }

  static transformCustomViewsReport(data) {
    const viewsReports = {
      customViewsReports: data.customViewsReports
    };
    return viewsReports;
  }
}

export { CustomViewsReportsService };
