const actions = {
  CUSTOM_VIEWS_REPORTS_BEGIN: 'CUSTOM_VIEWS_REPORTS_BEGIN',
  CUSTOM_VIEWS_REPORTS_SUCCESS: 'CUSTOM_VIEWS_REPORTS_SUCCESS',
  CUSTOM_VIEWS_REPORTS_ERR: 'CUSTOM_VIEWS_REPORTS_ERR',

  customViewsReportsBegin: () => {
    return {
      type: actions.CUSTOM_VIEWS_REPORTS_BEGIN,
    };
  },

  customViewsReportsSuccess: data => {
    return {
      type: actions.CUSTOM_VIEWS_REPORTS_SUCCESS,
      data,
    };
  },

  customViewsReportsErr: err => {
    return {
      type: actions.CUSTOM_VIEWS_REPORTS_ERR,
      err,
    };
  },
};

export default actions;
