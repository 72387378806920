import { PageHeader, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import FeatherIcon from 'feather-icons-react';
import Pagination from 'antd/es/pagination';
import { RotationTimeModal } from './components/RotationTimeModal';
import { usePendingsBoardTable } from './hooks/usePendingsBoardTable';
import { ColumnSettingsModal } from './components/ColumnSettingsModal';
import { usePendingsBoard } from './hooks/usePendingsBoard';
import { PendingsBoardTable } from './components/PendingsBoardTable';
import { ButtonClock, HeaderSpace, LogoImg, ReturnButton } from './Style';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { Main } from '../../container/styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import constants from '../../config/api/constants';

const PendingsBoard = () => {
  const { translate: t } = useDictionaryTranslation();
  const [isOpenRotationModal, setIsOpenRotationModal] = useState(false);
  const [isOpenListModal, setIsOpenListModal] = useState(false);
  const { logo, staticPrefix } = useSelector((state) => {
    return {
      logo: state.auth.accountData?.micitaMenuLogo,
      staticPrefix: state.auth.accountData?.staticPrefix,
    };
  });
  const logoUrl = useMemo(() => {
    return !logo ? null : `${constants.s3Path}/${staticPrefix}/${logo}`;
  });
  const { settingData, getPendingsSettings } = usePendingsBoard();
  const { tableData, getData, isLoading } = usePendingsBoardTable({ rotationTime: settingData?.rotationTime });

  const openListModal = () => {
    setIsOpenListModal(true);
  };

  const closeListModal = (success = false) => {
    if (success) {
      getPendingsSettings();
    }
    setIsOpenListModal(false);
  };

  const openRotationModal = () => {
    setIsOpenRotationModal(true);
  };

  const closeRotationModal = (success = false) => {
    if (success) {
      getPendingsSettings();
    }
    setIsOpenRotationModal(false);
  };

  console.log(logoUrl)

  return (
    <>
      <Space>
        <ReturnButton size="small">
          <Link to="/dashboard">
            <FeatherIcon icon="arrow-left" className="arrowIcon" />
          </Link>
        </ReturnButton>
        <LogoImg alt="account-logo" src={logoUrl ?? require('../../static/img/Logo_micita.png')} />
      </Space>
      <Main>
        <HeaderSpace>
          <Space>
            <PageHeader title={`${tableData.total} ${t('services')}`} ghost />
          </Space>
          <Space size="large">
            <Pagination
              pageSize={tableData.pageSize}
              current={tableData.page}
              total={tableData.total}
              showSizeChanger={false}
              onChange={(page) => {
                getData({ page });
              }}
              showTotal={(all) => `${all} registros totales`}
            />
            <ButtonClock type="#fff" color="black" size="small" shape="circle" onClick={openListModal}>
              <FeatherIcon icon="list" className="listIcon" />
            </ButtonClock>
            <ButtonClock type="primary" size="small" shape="circle" onClick={openRotationModal}>
              <FeatherIcon icon="clock" className="clockIcon" />
            </ButtonClock>
          </Space>
        </HeaderSpace>
        <Cards headless>
          <PendingsBoardTable
            settingData={settingData}
            tableData={tableData}
            isLoading={isLoading}
          />
        </Cards>
      </Main>
      {isOpenListModal ? <ColumnSettingsModal show={isOpenListModal} handleClose={closeListModal} /> : null}
      <RotationTimeModal show={isOpenRotationModal} handleClose={closeRotationModal} />
    </>
  );
};

export default PendingsBoard;
