import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Collapse, Typography, Row } from 'antd';
import { ModalAddBlokedDays } from './ModalAddBlockedDays';
import { Button } from '../../../components/buttons/buttons';

import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { TitleFormDefault } from '../../../container/styled';

const Text = Typography;
const { Panel } = Collapse;

export function ScheduleLocks({ value, onChange }) {
  const [blocks, setBlocks] = useState(value ?? []);
  const { translate: t } = useDictionaryTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openDaysModal = () => {
    setIsOpenModal(true);
  };

  const closeDaysModal = (newValue) => {
    if (Array.isArray(newValue)) {
      setBlocks(newValue);
      onChange(newValue);
    }
    setIsOpenModal(false);
  };

  useEffect(() => {
    setBlocks(value ?? []);
  }, [value]);

  return (
    <>
      <TitleFormDefault>
        {t('blockDays')}
      </TitleFormDefault>
      <h4>{t('blockDaysdescription')}</h4>
      <Collapse style={{ width: '60%' }}>
        <Panel
          header={
            <Row>
              <Col xs={12}>
                <Text style={{ paddingTop: '1%' }}>({blocks?.length ?? 0})  {t('blockedDays')}</Text>
              </Col>
              <Col xs={12}>
                <Button onClick={openDaysModal} outlined type="primary" style={{ border: 'none', marginLeft: '8em' }}>
                  {t('addService')}
                </Button>
              </Col>
            </Row>
          }
          key="1"
        >
          {blocks.map((block, index) => (
            <Row key={index} style={{ marginBottom: '10px' }}>
              <Col xs={24}>
                <Text style={{ paddingTop: '1%' }}> {moment(block.from).format('DD/MM/YYYY HH:mm:ss a')}</Text>
              </Col>
              <Col xs={24}>
                <Text style={{ paddingTop: '1%' }}> {moment(block.to).format('DD/MM/YYYY HH:mm:ss a')}</Text>
              </Col>
            </Row>
          ))}
        </Panel>
      </Collapse>
      {isOpenModal ? <ModalAddBlokedDays show={isOpenModal} blocks={blocks} handleClose={closeDaysModal} /> : null}
    </>
  );
}

ScheduleLocks.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};
