import React from 'react';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { VersionApp, MenuApp } from './style';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { useAppRoutes } from '../../hooks/useAppRoutes';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { translate: t } = useDictionaryTranslation();
  const { userRoutes: appRoutes, userCustomRoutes } = useAppRoutes();
  const { pathname } = useLocation();
  const currentPathName = pathname.replace(process.env.PUBLIC_URL, '');

  const getSubMenu = ({ name, icon, showIcon }, childrenNodes) => {
    return (
      <Menu.SubMenu
        // className='ant-menu-submenu ant-menu-submenu-inline'
        key={name}
        icon={showIcon && <FeatherIcon icon={icon} />}
        title={name}
      >
        {childrenNodes}
      </Menu.SubMenu>
    );
  };

  const getMenuItem = ({ path, onClick, name, icon, showIcon, key, collapseMenu, newTab }) => {
    return (
      <Menu.Item
        key={key}
        icon={
          showIcon && (
            <NavLink className="menuItem-iocn" to={path}>
              <FeatherIcon icon={icon} />
            </NavLink>
          )
        }
        className="menuItem-iocn"
      >
        {newTab ? (
          <NavLink target="_blank" rel="noopener noreferrer" onClick={() => onClick(collapseMenu)} to={path}>
            {name}
          </NavLink>
        ) : (
          <NavLink onClick={() => onClick(collapseMenu)} to={path}>
            {name}
          </NavLink>
        )}

      </Menu.Item>
    );
  };

  const mapRoutes = (routes, showIcon = true, prefix = '') => {
    return routes.map((route) => {
      if (route.redirect) return null;
      const itemPath = prefix + (route.defaultPath ?? route.path);
      const params = {
        key: itemPath,
        icon: route.icon,
        name: t(route.name),
        path: itemPath,
        newTab: route.newTab,
        showIcon,
        onClick: toggleCollapsed,
        collapseMenu: route.collapseMenu,
      };
      if (route.children) {
        return getSubMenu(params, mapRoutes(route.children, false, itemPath));
      }
      return getMenuItem(params);
    });
  };

  return (
    <>
      <MenuApp>
        <Menu
          mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
          theme={darkMode && 'dark'}
          defaultSelectedKeys={[currentPathName]}
          overflowedIndicator={<FeatherIcon icon="more-vertical" />}
        >
          {mapRoutes(appRoutes, !topMenu)}
          {mapRoutes(userCustomRoutes, !topMenu)}
        </Menu>
      </MenuApp>
      <VersionApp>
        <h3>V.{process.env.REACT_APP_VERSION}</h3>
      </VersionApp>
    </>
  );
};

MenuItems.propTypes = {
  darkMode: PropTypes.bool,
  topMenu: PropTypes.bool,
  toggleCollapsed: PropTypes.func,
};

export default MenuItems;
